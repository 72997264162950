import * as React from "react";
import {Container, Grid, Button} from "semantic-ui-react";
import HeaderData from "../components/layout/header"
import SocialIcons from "../components/socialIcons/socialIcons"
import Destacada from "../components/destacada/Destacada"
import Divider from "../components/Divider/Divider"
import FooterData from "../components/layout/footer"
import Recientes from '../components/sidebar/Recientes/Recientes'
import Link from 'gatsby-link'
import moment from 'moment'
import "moment/locale/es"
const IndexPage = ({ pageContext }) => {
  const { group, index, first, last, pageCount } = pageContext
  const previousUrl = index - 1 === 1 ? '/' : '/' + (index - 1).toString()
  const nextUrl = "/" + (index + 1).toString()
  return (
    <div>
      <Container fluid>
            <Grid>
                <Grid.Column computer={1} className="mobile hidden tablet hidden">
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={14}>
                    <HeaderData/>
                    
                </Grid.Column>
                <Grid.Column computer={1} className="mobile hidden tablet hidden">
                </Grid.Column>
            </Grid>
            <Grid>
                <Grid.Column computer={1} className="mobile hidden tablet hidden">
                    <SocialIcons />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={14}>
                    <Destacada/>
                </Grid.Column>
                <Grid.Column computer={1} className="mobile hidden tablet hidden">
                </Grid.Column>
            </Grid>
            <Divider/>
            <Grid>
                <Grid.Column computer={1} className="mobile hidden tablet hidden">
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={10}>  
                  <Container fluid>
                    <Grid>
                      {group.map(( group , i) => (
                          <Grid.Column computer={i > 0 && i < 5 ? 8 : 16} mobile={16} tablet={i === 0 ? 16 : 8}>
                            <Link  to={'/blog/'+group.node.slug}>
                              <div style={{border: 1 + 'px solid #000', marggingBottom: 1 + 'em', padding: 1 + 'em'}}>
                                  <div style={{ position:'relative', backgroundImage: `url(${group.node.featuredImage.node.localFile.childImageSharp.fluid.srcWebp === null ? '/static/images/no_image.jpg' : group.node.featuredImage.node.localFile.childImageSharp.fluid.srcWebp})`,backgroundSize: 'cover', paddingTop: 25 + 'em', backgroundPosition: 'center' }} >
                                    <span style={{textTransform: 'uppercase', padding:'.25em 1.5em',top: 0, right:0,position:'absolute',backgroundColor: '#000', color: '#fff'}}>Intereses</span>
                                  </div>
                                  <span style={{color: '#c3c3c3',fontStyle: 'italic',fontSize:1 + 'em', fontWeight:'100', display:'block',textTransform: 'capitalize'}}>{ moment(group.node.date).format(" D MMMM YYYY")}</span>
                                  <h2 style={{fontSize: '16px' ,margin: '0px', minHeight: '2.7em',color: '#000',fontWeight:'bold'}}>{group.node.title.substring(0, 150)}</h2>
                                  <div style={{minHeight: '5em' ,color: '#000',fontSize:1 + 'em', fontWeight:'100'}} dangerouslySetInnerHTML={{ __html: group.node.excerpt.substring(3, 150) }} />
                              </div>
                              </Link>
                          </Grid.Column>
                      ))}
                      <Grid.Column className={first === true ? 'hide' : 'center'} mobile={last === true ? 16 : 8} tablet={last === true ? 16 : 8} computer={last === true ? 16 : 8}>
                      <Link style={{border:'1px solid #000', padding:'1em .25em'}} to={previousUrl}><Button style={{margin: 0}} secondary>Cargar Menos</Button></Link>
                      </Grid.Column>
                      <Grid.Column className={last === true ? 'hide' : 'center'} mobile={first === true ? 16 : 8} tablet={first === true ? 16 : 8} computer={first === true ? 16 : 8}>
                      <Link style={{border:'1px solid #000', padding:'1em .25em'}} to={nextUrl}><Button style={{margin: 0}} secondary>Cargar Más</Button></Link>
                      </Grid.Column>
                    </Grid>
                  </Container>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={4}>
                <Recientes />
                </Grid.Column>
                <Grid.Column computer={1} className="mobile hidden tablet hidden">
                </Grid.Column>
            </Grid>
            <FooterData />
        </Container>
    </div>
  )
}
export default IndexPage