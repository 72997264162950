import React from "react"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import {Container, Grid} from "semantic-ui-react";
import { Link } from 'gatsby';
const Destacada = ({ data }) => (
    <Container fluid>
        <Grid>
            <Grid.Column computer={12} mobile={16} tablet={10}>
            <picture className={data.featuredImageDatabaseId > 0 ? 'no-hide' : 'hide'} >
              <source srcset={data.featuredImage.node.localFile.childImageSharp.fluid.srcSetWebp} type="image/webp" />
              <source srcset={data.featuredImage.node.localFile.childImageSharp.fluid.srcSet} type="image/jpeg" /> 
              <img src={data.featuredImage.node.localFile.childImageSharp.fluid.srcWebp} alt={data.title} style={{maxWidth: '100%',marginTop: '2em' ,padding: '1rem' ,border: 'solid 1px #000',}} />
            </picture>
            </Grid.Column>
            <Grid.Column style={{position:'relative'}} mobile={16} tablet={6} computer={4}>
            <article style={{marginTop: '3em'}} >
                <h2>{data.title}</h2>
                <section dangerouslySetInnerHTML={{__html:data.resumen.resumen}}/>
            </article>
                    <Link style={{position:'absolute', bottom: '0px', right: '0px', color: '#000'}} to={`/${data.slug}/`}>Ver más -></Link>
            </Grid.Column>
        </Grid>
    </Container>
  
)

export default function MyDestacada(props) {
  return (
    <StaticQuery
      query={graphql`
      query MyQuery {
        allWpPage(filter: {resumen: {destacada: {eq: "si"}}}) {
          nodes {
            title
            slug
            content
            featuredImageDatabaseId
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    fluid {
                      src
                      srcSet
                      srcSetWebp
                      srcWebp
                    }
                  }
                }
              }
            }
            resumen {
                resumen
              }
          }
        }
      }      
      `}
      render={data => <Destacada data={data.allWpPage.nodes['0']} {...props} />}
    />
  )
}

Destacada.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}