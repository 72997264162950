import React from "react"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import {Container, Grid} from "semantic-ui-react";
import logo from './img/divider.png'
const Divider = ({ data }) => (
    <Container fluid className="color_black the_divider">
    <Grid className="border">
      <Grid.Column computer={2} className=" mobile hidden tablet hidden">
      </Grid.Column>
      <Grid.Column computer={4} className=" mobile hidden tablet hidden">
      <img alt='Logo Poco a poco' style={{margin: "4.25em auto", width: '100%'}} src={logo} fluid />
      </Grid.Column>
      <Grid.Column computer={8} className=" mobile hidden tablet hidden">
        <span style={{fontWeight: '100 !important', verticalAlign: 'middle', lineHeight: '2.6em', color:'#fff', fontSize: '4em', textAlign: 'center', display: 'block'}}>Sin prisa pero sin pausa</span>
      </Grid.Column>
      <Grid.Column computer={2} className=" mobile hidden tablet hidden">
      </Grid.Column>
    </Grid>
  </Container>
  
)

export default function MyDivider(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
              logo
            }
          }
        }
      `}
      render={data => <Divider data={data} {...props} />}
    />
  )
}

Divider.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}