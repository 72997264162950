import React from "react"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import {Container, Grid, Icon} from "semantic-ui-react";
import Link from 'gatsby-link'
const SocialIcons = ({ data }) => (

  <div>
    <Container style={{marginTop: '10em'}} fluid>
    <Grid>
      <Grid.Column style={{padding: '0px'}} computer={16} className="mobile hidden tablet hidden">
      <Link className="center" to={data.instagram}><Icon style={{fontSize: '1.5em', margin: '.5em 2em'}} circular inverted name='instagram' /></Link>
      </Grid.Column>
      <Grid.Column style={{padding: '0px'}} computer={16} className=" mobile hidden tablet hidden">
      <Link className="center" to={data.youtube}><Icon style={{fontSize: '1.5em', margin: '.5em 2em'}} circular inverted name='youtube' /></Link>
      </Grid.Column>
      <Grid.Column style={{padding: '0px'}} computer={16} className=" mobile hidden tablet hidden">
      <Link className="center" to={data.twitter}><Icon style={{fontSize: '1.5em', margin: '.5em 2em'}} circular inverted name='twitter' /></Link>
      </Grid.Column>
    </Grid>
  </Container>
  </div>
  
)

export default function MySocialIcons(props) {
    return (
      <StaticQuery
        query={graphql`
        query Social {
          allSite {
            edges {
              node {
                siteMetadata {
                  youtube
                  twitter
                  instagram
                }
              }
            }
          }
        }
        `}
        render={data => <SocialIcons data={data.allSite.edges['0'].node.siteMetadata} {...props} />}
      />
    )
  }
  
  SocialIcons.propTypes = {
    data: PropTypes.shape({
      site: PropTypes.shape({
        siteMetadata: PropTypes.shape({
          title: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }