import React from "react"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import {Container, Grid} from "semantic-ui-react";
const Recientes = ({ data }) => {
    const { allWpPost } = data    
    return (
    <Container style={{marginTop: '2em'}} fluid>
        <Grid style={{pading: '2em'}}>
            <Grid.Column mobile={16} tablet={16} computer={16} style={{backgroundColor: '#000'}}>
                <h2 style={{fontSize: '16px',color: '#fff',textAlign:'center' }}>Más recientes</h2>
            </Grid.Column>
            {allWpPost.edges.map(( post , i) => (
            <Container style={{marginTop: '2em'}}>
                <Grid>    
                <Grid.Column computer={8} mobile={16} tablet={8}>
                <div style={{padding: '3.5em 0', backgroundImage: `url(${post.node.featuredImage.node.localFile.url})`,backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                </Grid.Column>
                <Grid.Column style={{padding: '3em 0'}} mobile={16} tablet={8} computer={8}>
                    <h2 style={{fontSize: '16px', margin: '0px'}}>{post.node.title}</h2>
                    <p>{post.node.date}</p>
                </Grid.Column>
                </Grid>
            </Container>
            ))}    
            
        </Grid>
    </Container>
)
}

export default function MyRecientes(props) {
  return (
    <StaticQuery
      query={graphql`
      query Reciente {
        allWpPost(
            filter: {status: {eq: "publish"}}
            sort: {order: DESC, fields: date}
            limit: 3
            ) {
                edges {
                    node {
                        date
                        title
                        slug
                        featuredImage {
                            node {
                                localFile {
                                    url
                                }
                            }
                        }
                    }
                }
            }
        }
        `}
      render={data => <Recientes data={data} {...props} />}
    />
  )
}

Recientes.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}